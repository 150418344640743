<template>
  <v-img
    :src="src"
    @error="imageError"
    :max-width="maxWidth"
    :max-height="maxHeight"
    contain
  >
    <template #placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import noPhotoImg from '@/assets/nophoto.png'

const imageProductUrlFull = process.env.VUE_APP_IMAGE_PRODUCT_URL_FULL
const imageProductUrlPreview = process.env.VUE_APP_IMAGE_PRODUCT_URL_PREVIEW

export default {
  name: 'VProductImage',
  props: {
    product_id: {
      required: true
    },
    maxWidth: {
      type: Number,
      default: 250
    },
    maxHeight: {
      type: Number,
      default: 250
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    failure: false
  }),
  computed: {
    src() {
      let url = this.preview
        ? imageProductUrlPreview
            .replace('%w', this.maxWidth)
            .replace('%h', this.maxHeight)
        : imageProductUrlFull

      return this.failure ? noPhotoImg : url.replace('%s', this.product_id)
    }
  },
  methods: {
    imageError() {
      this.failure = true
    },
  },
  watch: {
    product_id() {
      this.failure = false;
    }
  }
}
</script>
