<template>
  <v-container fluid>
    <div v-if="getterRoomProduct">
      <v-row>
        <v-col align="center" class="text-subtitle-1 font-weight-bold pb-4">
          {{ name }}
        </v-col>
      </v-row>
      <v-row class="flex-column flex-sm-row">
        <v-col align="center">
          <v-product-image :product_id="id" />
        </v-col>
        <v-col>
          <div class="price">
            {{ price }} руб.
            <span v-if="unit">/ {{ unit }}</span>
          </div>
          <div v-if="description" class="text-body-2 pt-3">
            {{ description | truncate(650) }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template #default>
              <tbody>
                <tr v-for="item in features" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <div v-else class="pt-12 text-center text-subtitle-1 font-weight-bold">
      Нет информации о товаре
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  filters: {
    truncate(str, length) {
      if (str.length > length) {
        let truncatedStr = str.substring(0, length)
        return `${truncatedStr.substring(0, truncatedStr.lastIndexOf(' '))}...`
      }
      return str
    }
  },
  computed: {
    ...mapGetters('rooms', ['getterRoomProduct']),
    id() {
      return this.getterRoomProduct['id'] || ''
    },
    name() {
      return this.getterRoomProduct['name'] || ''
    },
    description() {
      return this.getterRoomProduct['description'] || ''
    },
    features() {
      const {
        brand,
        amount_in_a_package,
        volume,
        weight
      } = this.getterRoomProduct
      const features = this.getterRoomProduct['features']
        ? [...this.getterRoomProduct['features']]
        : []
      if (brand) {
        features.unshift({ name: 'Бренд', value: brand })
      }
      if (amount_in_a_package) {
        features.unshift({
          name: 'Количество в упаковке',
          value: amount_in_a_package
        })
      }
      if (volume) {
        features.unshift({ name: 'Объем, м3', value: volume })
      }
      if (weight) {
        features.unshift({ name: `Вес за 1${this.unit}`, value: weight })
      }
      // фильтрую на случай, когда из 1с пришли пустые значения
      return features.filter(item => item.name && item.value !== '')
    },
    price() {
      return this.getterRoomProduct['price'] || ''
    },
    unit() {
      return this.getterRoomProduct['unit'] || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  font-size: 1.5rem !important;
  color: #212121;
}
</style>
