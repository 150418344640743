<template>
  <v-app id="app-main">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import main from '@/api/main'

export default {
  name: 'App',
  mounted() {
    this.workingObserver()
  },
  methods: {
    workingObserver() {
      main
        .isWorkingNow()
        .then(({ data: { work } }) => {
          if (!work)
            this.$router.push({ name: 'time-over' }, () => {})
          else if (this.$route.name === 'time-over')
            this.$router.push({ name: 'index' }, () => {})
        })
        .catch(() => {
          this.$router.push({ name: 'time-over' }, () => {})
        })
        .finally(() => {
          setTimeout(this.workingObserver, 3000)
        })
    }
  }
}
</script>

<style lang="scss">
.thin-table {
  td {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
</style>
