import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import App from './App.vue'
import router from './router.js'
import { createStore } from './store'
import vuetify from './plugins/vuetify'
import './plugins/vuesocket/'
import './components/global/register-globals'
import './assets/common.scss'

Vue.config.productionTip = false

const sentryEnabled = process.env.VUE_APP_SENTRY_ENABLE === '1'
const sentryLink = process.env.VUE_APP_SENTRY_LINK
if (sentryEnabled && sentryLink) {
  Sentry.init({
    dsn: sentryLink,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        tracingOptions: {
          trackComponents: true
        }
      })
    ]
  })
}

const store = createStore()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
