<template>
  <v-container fill-height fluid class="d-flex align-center justify-center">
    <span class="text-center">
      <v-icon color="green" size="80">mdi-clock-time-eight-outline</v-icon>
      <br>
      <div class="grey--text pt-8">Сервис закрыт. Рабочее время закончилось</div>
    </span>
  </v-container>
</template>

<script>
export default {
name: "TimeOver"
}
</script>