<template>
  <v-container fill-height fluid class="align-center justify-center">
    <span class="text-center">
      <v-icon color="grey" size="80">mdi-emoticon-dead</v-icon>
      <br>
      <div class="grey--text pt-8">Упс! Что-то пошло не так...</div>
    </span>
  </v-container>
</template>

<script>
export default {
  name: "Oops"
}
</script>

<style scoped>

</style>