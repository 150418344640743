import WebSocketWrapper from './WebSocketWrapper'

export default class VueSocket {
  /**
   * @param url
   */
  constructor(url) {
    this.url = url
  }

  /**
   * @param Vue
   */
  install(Vue) {
    Vue.prototype.$socket = ({
      path = '',
      name = '',
      reconnect = false
    }) => {
      const connection = `${this.url}${path}`
      return new WebSocketWrapper({ connection, reconnect, name })
    }
  }
}
