<template>
  <v-btn @click.stop="toggle" color="blue-grey darken-4" icon>
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'VFullScreenSwitcher',
  data: () => ({
    fullscreenEnabled: false
  }),
  computed: {
    icon() {
      return this.fullscreenEnabled ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
    }
  },
  methods: {
    toggle() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
        this.fullscreenEnabled = true
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
          this.fullscreenEnabled = false
        }
      }
    }
  }
}
</script>
