// Код обычного закрытия соединения
const NORMAL_CLOSE_CODE = 1000
// Количество милисекунд, на которое будет увеличиваться следующая попытка переподключения в милисекундах
const RECONNECTION_TIMEOUT_INCREASE_VALUE = 1000
// Максимальное время ожидания переподключения
const MAX_RECONNECTION_TIMEOUT = 30000

// Стандартные события WS
const EVENT_OPEN = 'open'
const EVENT_MESSAGE = 'message'
const EVENT_CLOSE = 'close'
const EVENT_ERROR = 'error'
// Нестандартное событие
const EVENT_RECONNECT = 'reconnect'

export default class WebSocketWrapper {
  /**
   * @param connection
   * @param reconnect
   * @param reconnectTimeout
   */
  constructor({ connection, name = '', reconnect = false }) {
    // имя соединения, используется только для вывода в консоль
    this.name = name || connection
    // true при дисконнекте
    this.disconnected = false
    // время задержки перед следующей попыткой подключения
    this.reconnectTimeout = 0
    // список зарегистрированных событий
    this.eventListeners = []
    this.connect({ connection, reconnect })
  }

  /**
   * Подключение
   * @param params
   */
  connect(params) {
    this.ws = new WebSocket(params.connection)
    this.eventListeners.forEach(listener => {
      this.ws.addEventListener(listener.name, listener.handler)
    })
    this.ws.addEventListener(EVENT_OPEN, () => {
      console.log(`WS OPENED: ${this.name}`)
      if (this.disconnected) {
        this.ws.dispatchEvent(new Event(EVENT_RECONNECT))
      }
      this.reconnectTimeout = 0
      this.disconnected = false
    })
    if (params.reconnect) {
      this.ws.addEventListener(EVENT_CLOSE, ({ code }) => {
        if (code !== NORMAL_CLOSE_CODE) {
          console.log(
            `WS RECONNECT: ${this.name} [in ${this.reconnectTimeout} ms]`
          )
          this.disconnected = true
          setTimeout(() => this.connect(params), this.reconnectTimeout)
        }
      })
      this.ws.addEventListener(EVENT_ERROR, () => {
        if (this.reconnectTimeout < MAX_RECONNECTION_TIMEOUT) {
          this.reconnectTimeout += RECONNECTION_TIMEOUT_INCREASE_VALUE
        }
      })
    }
  }

  /**
   * Обработчик события "close"
   * @param f
   * @returns {WebSocketWrapper}
   */
  set onclose(f) {
    this.eventListeners.push({ name: EVENT_CLOSE, handler: f })
    this.ws.addEventListener(EVENT_CLOSE, f)
    return this
  }

  /**
   * Обработчик события "message"
   * @param f
   * @returns {WebSocketWrapper}
   */
  set onmessage(f) {
    this.eventListeners.push({ name: EVENT_MESSAGE, handler: f })
    this.ws.addEventListener(EVENT_MESSAGE, f)
    return this
  }

  /**
   * Обработчик события "open"
   * @param f
   * @returns {WebSocketWrapper}
   */
  set onopen(f) {
    this.eventListeners.push({ name: EVENT_OPEN, handler: f })
    this.ws.addEventListener(EVENT_OPEN, f)
    return this
  }

  /**
   * Обработчик события "reconnect"
   * @param f
   * @returns {WebSocketWrapper}
   */
  set onreconnect(f) {
    this.eventListeners.push({ name: EVENT_RECONNECT, handler: f })
    this.ws.addEventListener(EVENT_RECONNECT, f)
    return this
  }

  /**
   * Обработчик события "error"
   * @param f
   * @returns {WebSocketWrapper}
   */
  set onerror(f) {
    this.eventListeners.push({ name: EVENT_ERROR, handler: f })
    this.ws.addEventListener(EVENT_ERROR, f)
    return this
  }

  /**
   * Закрытие соединения
   */
  close() {
    this.ws.close()
  }

  /**
   * Отправка сообщения
   * @param data
   */
  send(data) {
    this.ws.send(data)
  }

  /**
   * Код обычного закрытия соединения
   * @returns {number}
   * @constructor
   */
  get NORMAL_CLOSE_CODE() {
    return NORMAL_CLOSE_CODE
  }
}
