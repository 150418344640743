export default {
  /**
   * Отображаемый экран
   * @param display
   * @returns {*}
   */
  getterRoomDisplay({ display }) {
    return display
  },

  /**
   * Информация о товаре
   * @param data
   * @returns {*|(function(*): null)|Function|null}
   */
  getterRoomProduct({ product_data }) {
    return product_data || {}
  },

  /**
   * Информация о заказе
   * @param data
   * @returns {*}
   */
  getterRoomOrder({ order_data }) {
    return order_data || {}
  }
}
