var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[(_vm.orderId)?_c('div',{staticClass:"fill-height col-12"},[_c('div',{staticClass:"text-center text-subtitle-1 font-weight-bold pb-4"},[_vm._v(" Заказ №"+_vm._s(_vm.orderId)+" ")]),(_vm.items.length)?_c('v-data-table',{staticClass:"thin-table col-12 d-none d-md-block",attrs:{"id":"order_items","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","disable-sort":"","dense":"","mobile-breakpoing":""},scopedSlots:_vm._u([{key:"item.image",fn:function({ item }){return [_c('div',{staticClass:"my-2 d-flex justify-center"},[_c('v-product-image',{attrs:{"preview":"","product_id":item.product_id,"max-width":50,"max-height":50}})],1)]}},{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.count",fn:function({ item }){return [_vm._v(" "+_vm._s(item.count)+" "+_vm._s(item.unit)+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(item.unit_price)+" ")]}},{key:"item.result_sum",fn:function({ item }){return [_vm._v(" "+_vm._s(item.result_sum)+" ")]}}],null,false,1493680640)}):_vm._e(),_vm._l((_vm.items),function(item){return _c('div',{key:item.product_id,staticClass:"d-block d-md-none py-2 my-2 alternative-order-table-row"},[_c('div',{class:{
          'd-flex': true,
          'mb-5': true,
          'flex-row': _vm.windowWidth >= 425,
          'flex-column': _vm.windowWidth < 425,
        }},[_c('v-product-image',{class:{
            'mx-auto': _vm.windowWidth < 425,
            'mb-4': _vm.windowWidth < 425,
            'mr-4': _vm.windowWidth >= 425

          },attrs:{"preview":"","product_id":item.product_id,"max-width":_vm.windowWidth < 425 ? 120 : 80,"max-height":_vm.windowWidth < 425 ? 120 : 80}}),_c('div',[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"d-flex justify-space-between item-options"},[_c('span',[_vm._v("Кол-во: "+_vm._s(item.count)+" "+_vm._s(item.unit))]),_c('span',[_vm._v("Цена за ед.: "+_vm._s(item.unit_price)+" ₽")]),_c('span',[_vm._v("Сумма к оплате: "+_vm._s(item.result_sum)+" ₽")])])])}),(_vm.items.length)?_c('div',{staticClass:"text-right subtitle-2 pt-3"},[_c('span',{staticClass:"font-weight-black"},[_vm._v("К оплате:")]),_vm._v(" "+_vm._s(_vm.orderTotalPrice)+" ₽ ")]):_vm._e(),(!_vm.items.length)?_c('div',{staticClass:"pt-8 text-center text-subtitle-1 font-italic grey--text text--darken-1"},[_vm._v(" нет элементов заказа ")]):_vm._e()],2):_c('div',{staticClass:"pt-12 text-center text-subtitle-1 font-weight-bold"},[_vm._v(" Нет информации о заказе ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }