import getters from './getters'
import mutations from './mutations'

const state = {
  order_data: null,
  product_data: null,
  display: null,
  token: null
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
