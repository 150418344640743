import axios from 'axios'

const host = process.env.VUE_APP_API_HOST

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

export default axios.create({
  baseURL: `${host}/api`,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  }
})

