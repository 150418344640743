<template>
  <v-container fill-height fluid class="d-flex align-center justify-center">
    <div class="text-center d-flex flex-column justify-end">
      <v-icon color="green" size="80">mdi-check-all</v-icon>
      <div class="grey--text py-8">Обслуживание завершено!</div>
      <v-btn class="mt-16 grey--text white" text :to="{ name: 'index' }">
        Продолжить
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceOver'
}
</script>
