import * as types from './types'

export default {
  [types.ROOMS_ORDER_DATA_SET]: (state, data) => {
    state.order_data = data
  },

  [types.ROOMS_PRODUCT_DATA_SET]: (state, data) => {
    state.product_data = data
  },

  [types.ROOMS_DISPLAY_SET]: (state, value) => {
    state.display = value
  }
}
