<template>
  <div>
    <div v-if="orderId">
      <v-row>
        <v-col align="center" cols="12" class="pt-12 pb-8">
          <v-img :src="imageSrc" max-width="250" max-height="250" />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="price">
          К оплате: {{ totalPrice }} руб.
        </v-col>
      </v-row>
    </div>
    <div v-else class="pt-12 text-center text-subtitle-1 font-weight-bold">
      Нет информации об оплате
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import noPhotoImg from '@/assets/nophoto.png'

export default {
  computed: {
    ...mapGetters('rooms', ['getterRoomOrder']),
    orderId() {
      return this.getterRoomOrder['id'] || ''
    },
    imageSrc() {
      return this.getterRoomOrder['qr'] || noPhotoImg
    },
    totalPrice() {
      return this.getterRoomOrder['total_price'] || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  font-size: 1.5rem !important;
  color: #212121;
}
</style>
