<template>
  <v-container fluid fill-height>
    <div v-if="orderId" class="fill-height col-12">
      <div class="text-center text-subtitle-1 font-weight-bold pb-4">
        Заказ №{{ orderId }}
      </div>

      <v-data-table
        id="order_items"
        v-if="items.length"
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        class="thin-table col-12 d-none d-md-block"
        hide-default-footer
        disable-sort
        dense
        mobile-breakpoing
      >
        <template #item.image="{ item }">
          <div class="my-2 d-flex justify-center">
            <v-product-image preview :product_id="item.product_id" :max-width="50" :max-height="50"/>
          </div>
        </template>
        <template #item.name="{ item }">
          {{ item.name }}
        </template>
        <template #item.count="{ item }">
          {{ item.count }} {{ item.unit }}
        </template>
        <template #item.price="{ item }">
          {{ item.unit_price }}
        </template>
        <template #item.result_sum="{ item }">
          {{ item.result_sum }}
        </template>
      </v-data-table>

      <div
        class="d-block d-md-none py-2 my-2 alternative-order-table-row"
        v-for="item in items"
        :key="item.product_id"
      >
        <div
          :class="{
            'd-flex': true,
            'mb-5': true,
            'flex-row': windowWidth >= 425,
            'flex-column': windowWidth < 425,
          }"
        >
          <v-product-image
            preview
            :product_id="item.product_id"
            :max-width="windowWidth < 425 ? 120 : 80"
            :max-height="windowWidth < 425 ? 120 : 80"
            :class="{
              'mx-auto': windowWidth < 425,
              'mb-4': windowWidth < 425,
              'mr-4': windowWidth >= 425

            }"
          />
          <div>{{ item.name }}</div>
        </div>
        <div class="d-flex justify-space-between item-options">
          <span>Кол-во: {{ item.count }} {{ item.unit }}</span>
          <span>Цена за ед.: {{ item.unit_price }} ₽</span>
          <span>Сумма к оплате: {{ item.result_sum }} ₽</span>
        </div>
      </div>

      <div v-if="items.length" class="text-right subtitle-2 pt-3">
        <span class="font-weight-black">К оплате:</span>
        {{ orderTotalPrice }} ₽
      </div>
      <div
        v-if="!items.length"
        class="pt-8 text-center text-subtitle-1 font-italic grey--text text--darken-1"
      >
        нет элементов заказа
      </div>
    </div>
    <div v-else class="pt-12 text-center text-subtitle-1 font-weight-bold">
      Нет информации о заказе
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    headers: [
      {
        text: 'Изображение',
        value: 'image',
        align: 'center'
      },
      {
        text: 'Информация',
        value: 'name',
        align: 'left',
        width: '90%'
      },
      {
        text: 'Кол-во',
        value: 'count',
        align: 'center',
        width: '100px'
      },
      {
        text: 'Цена за ед. ₽',
        value: 'price',
        align: 'center',
        width: '100px'
      },
      {
        text: 'Сумма к оплате. ₽',
        value: 'result_sum',
        align: 'center',
        width: '100px'
      }
    ],
    itemsPerPage: 1000
  }),
  computed: {
    ...mapGetters('rooms', ['getterRoomOrder']),
    windowWidth() {
      return window.innerWidth
    },
    orderId() {
      return this.getterRoomOrder['id'] || ''
    },
    orderTotalPrice() {
      return this.getterRoomOrder['total_price'] || ''
    },
    items() {
      const { items } = this.getterRoomOrder
      if (items) {
        return items.map(item => {
          return {
            product_id: item.id,
            name: item.name,
            count: item.count,
            unit: item.unit,
            unit_price: item.unit_price,
            result_sum: item.result_sum
          }
        })
      }
      return []
    }
  }
}
</script>

<style lang="scss">
#order_items td {
  height: auto !important;
}

.alternative-order-table-row {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);

  .item-options {
    font-family: BloggerSansLight, monospace;

    @media (max-width: 600px) {
      flex-direction: column;
      font-size: unset;
    }
  }
}
</style>
