<template>
  <v-container
      :class="{
        'pa-3': true,
        'd-flex': true,
        'align-start': roomSocket,
        'justify-center': roomSocket
      }"
      fluid
      fill-height
  >
    <div
        v-if="!roomSocket"
        class="mx-auto d-flex flex-column"
    >
      <div id="pin_digits" class="mx-auto mb-16">
        <div class="digit_container justify-space-between align-center d-flex">
          <div>{{ pin[0] }}</div>
          <div>{{ pin[1] }}</div>
          <div>{{ pin[2] }}</div>
          <div>{{ pin[3] }}</div>
          <div>{{ pin[4] }}</div>
          <div>{{ pin[5] }}</div>
        </div>
        <div style="position: absolute" class="red--text text-info pt-2" v-if="pinErrors">
          {{ pinErrors.join(', ') }}
        </div>
      </div>
      <table id="keyboard" class="mt-8">
        <tr>
          <td>
            <v-btn text @click="putDigit(1)">1</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(2)">2</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(3)">3</v-btn>
          </td>
        </tr>
        <tr>
          <td>
            <v-btn text @click="putDigit(4)">4</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(5)">5</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(6)">6</v-btn>
          </td>
        </tr>
        <tr>
          <td>
            <v-btn text @click="putDigit(7)">7</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(8)">8</v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(9)">9</v-btn>
          </td>
        </tr>
        <tr>
          <td>
            <v-btn style="font-family: 'Material Design Icons'; color: gray" text @click="clear()">
              &#983382;
            </v-btn>
          </td>
          <td>
            <v-btn text @click="putDigit(0)">0</v-btn>
          </td>
          <td>
            <v-btn @click="backspace()" style="font-family: 'Material Design Icons'; color: gray" text>
              &#985948;
            </v-btn>
          </td>
        </tr>
      </table>
    </div>
    <div
        v-else-if="roomSocket && roomSocket.disconnected"
        class="text-center mt-16 mx-auto"
    >
      <v-progress-circular
          size="40"
          width="3"
          color="primary"
          indeterminate
      ></v-progress-circular>
      <br/>
      <h3 class="mt-5 grey--text font-weight-light">Подключение...</h3>
    </div>
    <component v-else-if="roomSocket && !roomSocket.disconnected" :is="componentToDisplay"></component>
  </v-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import * as displayTypes from '@/common/displayTypes'
import RoomAddView from './RoomAddView'
import RoomOrderView from './RoomOrderView'
import RoomProductView from './RoomProductView'
import RoomQRView from './RoomQRView'
import * as roomMutationTypes from '@/store/modules/rooms/types'
import roomsApi from '@/api/rooms'

const componentsMap = {
  [displayTypes.add]: RoomAddView,
  [displayTypes.product]: RoomProductView,
  [displayTypes.order]: RoomOrderView,
  [displayTypes.qr]: RoomQRView
}

export default {
  data: () => ({
    pin: '',
    pinErrors: null,
    enterInProgress: false,
    roomSocket: null
  }),
  computed: {
    ...mapGetters('rooms', ['getterRoomDisplay']),
    componentToDisplay() {
      if (this.getterRoomDisplay) {
        return componentsMap[this.getterRoomDisplay]
      } else {
        return null
      }
    }
  },
  methods: {
    putDigit(digit) {
      this.pinErrors = null

      if (this.pin.length < 6) {
        this.pin += digit

        if (this.pin.length === 6)
          this.tryToOpenRoom()
      }
    },

    backspace() {
      this.pinErrors = null

      if (this.pin.length)
        this.pin = this.pin.substring(0, this.pin.length - 1)
    },

    clear() {
      this.pin = ''
      this.pinErrors = null
    },

    tryToOpenRoom() {
      this.enterInProgress = true

      roomsApi
          .getRoomToken(this.pin)
          .then(({token}) => {
            this.roomSocket = this.$socket({
              name: `room_${token}`,
              path: `/ws/room/${token}/`,
              reconnect: true,
            })

            this.roomSocket.onmessage = ({data}) => {
              const msg = JSON.parse(data)

              if (msg.signal) {
                if (msg.signal === 'deleted') {
                  this.roomSocket.close();
                  this.roomSocket = null
                  this.$router.push({name: 'service-over'}, () => {
                  })
                } else {
                  throw `Unexpected WS signal (in /ws/rooms/${token}/): ${msg.signal}`
                }
              } else {
                this.mutationRoomsDisplaySet(msg.display)
                this.mutationRoomsOrderDataSet(msg.order_data)
                this.mutationRoomsProductDataSet(msg.display_product)
              }
            }
          })
          .catch(e => {
            this.pinErrors = [e.response ? e.response.data.detail : e.toString()]
          })
          .finally(() => {
            this.enterInProgress = false
          })
    },
    ...mapMutations('rooms', {
      mutationRoomsOrderDataSet: roomMutationTypes.ROOMS_ORDER_DATA_SET,
      mutationRoomsProductDataSet: roomMutationTypes.ROOMS_PRODUCT_DATA_SET,
      mutationRoomsDisplaySet: roomMutationTypes.ROOMS_DISPLAY_SET
    })
  }
}
</script>

<style lang="scss">
* {
  user-select: none;
}

#pin_digits {
  width: 92%;

  .digit_container div {
    font-size: 40px;
    border-bottom: 3px lightgray solid;
    text-align: center;
    font-family: BloggerSansLight, monospace;
    width: 30px;
    height: 60px;
  }
}

#keyboard {
  border-collapse: collapse;

  tr {
    td {
      border: 1px solid lightgray !important;
      text-align: center;

      &:first-child {
        border-left-color: transparent !important;
      }

      &:last-child {
        border-right-color: transparent !important;
      }

      .v-btn {
        width: 40px;
        height: 40px;
        padding: 50px;
        font-size: 3rem !important;
        font-weight: lighter;
        font-family: BloggerSansLight;
      }
    }

    &:first-child td {
      border-top-color: transparent !important;
    }

    &:last-child td {
      border-bottom-color: transparent !important;
    }
  }
}
</style>
